import 'uni-pages';import 'uni-h5';import Vue from 'vue'
import App from './App.vue'

// vuex
import store from '@/store'
Vue.prototype.$store = store
// uview
import uView from 'uview-ui'
Vue.use(uView);
// composition-api
import VueCompositionAPI from '@vue/composition-api'
Vue.use(VueCompositionAPI)
// request
import '@/util/request'
// mixin
import mixin from '@/common/mixin.js'
Vue.mixin(mixin)

Vue.config.productionTip = false
App.mpType = 'app'

const app = new Vue({
	...App
})
app.$mount()