import { isEmpty, isNumber } from "@/util/is.js"
import { authorize } from '@/hooks/useAuthorize.js'

/**
 * 复制文本
 * @param { any } content 复制内容
 */
export function copy(content) {



		uni.setClipboardData({
			data: String(content),
			success() {
				uni.showToast({ title: '复制成功', })
			},
			fail() {
				uni.showToast({ icon: 'none', title: '复制失败' })
			}
		})



}

/**
 * 预览图片
 * @param { string | string[] } imgs 图片
 */
export function preview(imgs, current = 0) {
	uni.previewImage({
		urls: Array.isArray(imgs) ? imgs : [imgs],
		current
	})
}

/**
 * 拆分金额，根据小数点拆分
 * @param { string } amount
 * @param { 'element' | 'branch' } unit
 */
export function splitPrice(amount, unit) {
	if (!isNumber(amount)) return '0'
	if (unit == 'element') {
		return amount.substr(0, amount.indexOf('.'))
	} else if (unit == 'branch') {
		return amount.substr(amount.indexOf('.') + 1, 2)
	} else {
		return amount
	}
}

/**
 * 创建单独函数防抖（连续操作，最后一次生效）
 * @param { Function } func 函数
 * @param { number } wait 等待毫秒数
 */
export function createDebounce(func, wait = 500) {
	let timerId;
	return function () {
		if (timerId) clearTimeout(timerId);

		timerId = setTimeout(function () {
			func();
		}, wait);
	}
}

/**
 * 深度克隆
 * @param { any } obj
 */
export function deepClone(obj) {
	return uni.$u.deepClone(obj)
}

/**
 * 返回url路径携带的参数
 * @param { string } name 变量名
 */
export function getQueryString(name) {
	const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i');
	const r = window.location.search.substr(1).match(reg);
	if (r != null) {
		return unescape(r[2]);
	}
	return null;
}

/**
 * 删除url路径携带的参数
 * @param { string[] } name 变量名
 */
export function deleteQueryString(name) {
	const url = window.location.href;
	const params = (window.location.search || '?').substring(1).split('&');
	const prefix = url.substring(0, url.indexOf('?'));
	let suffix = '';
	for (let i = params.length - 1; i >= 0; i--) {
		const param = params[i];
		const key = param && param.split('=', 2)[0];
		if (!param || name.indexOf(key) !== -1) {
			params.splice(i, 1);
		}
	}
	if (params.length) {
		suffix = '?' + params.join('&');
	}
	return prefix + suffix
}

/**
 * promise超时
 * @param { number } ms 超时时间
 * @param { boolean } throwOnTimeout 超时是否抛出异常
 * @param { string } reason 错误原因
 */
export function promiseTimeout(ms, throwOnTimeout = false, reason = 'Timeout') {
	return new Promise((resolve, reject) => {
		if (throwOnTimeout) {
			setTimeout(() => reject(reason), ms)
		} else {
			setTimeout(resolve, ms)
		}
	})
}

/**
 * 递归查询
 * @template T
 * @param { T[] } data
 * @param { number | string } value 比较的值
 * @param { string } key 比较的字段
 * @param { string } childKey 子数据字段
 * @returns { T | null }
 */
export function recursive(data, value, key = 'id', childKey = 'children') {
	for (let i = 0; i < data.length; i++) {
		const item = data[i];
		if (item[key] == value) {
			return item;
		} else if (item[childKey]) {
			const result = recursive(item[childKey], value);
			if (result) return result;
		}
	}
	return null;
}

/**
 * 保存挂载课程id，用于快速挂载
 * @param { number | string } id 课程id
 */
export function saveMountId(id) {
	uni.getStorage({
		key: 'mount_ids',
		success: ({ data }) => {
			if (!data.includes(id)) {
				uni.setStorage({ key: 'mount_ids', data: [...data, id] })
			}
		},
		fail: () => {
			uni.setStorage({ key: 'mount_ids', data: [id] })
		}
	})
}

/**
 * 获取挂载课程ids
 */
export function getMountIds() {
	const data = uni.getStorageSync('mount_ids')

	return !isEmpty(data) ? data : []
}