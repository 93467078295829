import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
import config from '@/common/config'

Vue.use(Vuex) // vue的插件机制

// Vuex.Store 构造器选项
const store = new Vuex.Store({
	plugins: [
		persistedState({
			paths: ['$userInfo', '$localConfig', '$drivingConfig', '$volunteerConfig', '$otherConfig'],
			storage: {
				getItem: key => uni.getStorageSync(key),
				setItem: (key, value) => uni.setStorageSync(key, value),
				removeItem: key => uni.removeStorageSync(key)
			}
		})
	],
	// 为了不和页面或组件的data中的造成混淆，state中的变量前面建议加上$符号
	state: {
		// 用户信息
		$userInfo: {
			user_id: 0,
			spread_id: 0,
			avatar: 'https://zhishi.sdwanyue.com/default.png',
			nickname: '',
			mobile: '',
			is_auth: false,
			access_token: ''
		},
		$localConfig: {
			search: {
				tip: '',
				hot: [],
				history: []
			},
			shareConfig: {
				desc: "学知识，学技能",
				title: `学知识就上${config.appName}`
			}
		},
		$drivingConfig: {
			model: 'xc',
			subject: 1,
			position: '全国',
			position_code: 0,
			first_panel: true,
			show_action: true,
			first_answer: true,
			auto_broadcast: false,
			auto_next_question: true,
			question_panel_size: 'small',
			auto_remove: true,
			record_offset: {
				1: 0,
				4: 0
			},
			category_offset: {

			},
			record_config: {
				1: {
					total: 0,
					speed: 0,
					offset: 0,
					error: 0,
					success: 0,
					collect: 0,
					max_score: 0,
					avg_score: 0,
					qualified: 0,
					examination: 0,
					today_error: [],
					today_collect: []
				},
				4: {
					total: 0,
					speed: 0,
					offset: 0,
					error: 0,
					success: 0,
					collect: 0,
					max_score: 0,
					avg_score: 0,
					qualified: 0,
					examination: 0,
					today_error: [],
					today_collect: []
				},
			},
			record_config_time: '',
		},
		$volunteerConfig: {
			position: 0,
			type: 0,
			xuanke_first: '',
			xuanke: '',
			score: '',
			rank: '',
			batchId: 0,
			sheet: {},
		},
		$otherConfig: {
			belong_creator_id: undefined
		},
		h5LoginShow: false
	},
	mutations: {
		//设置用户信息
		setUserInfo(state, payload) {
			if (!payload?.access_token) {
				payload.access_token = state.$userInfo.access_token
			}
			state.$userInfo = payload;

			state.$userInfo.is_auth = true;

			uni.setStorage({ key: 'token', data: `Bearer ${payload.access_token}` });
		},
		setToken(state, payload) {
			state.$userInfo.access_token = payload;
			uni.setStorage({ key: 'token', data: `Bearer ${payload}` });
		},
		//设置归属创作者ID
		setBelongCreatorId(state, payload) {
			if (payload) {
				state.$otherConfig.belong_creator_id = payload;
			}
		},
		//退出登陆
		logout(state) {
			state.$userInfo = {
				user_id: 0,
				spread_id: 0,
				avatar: 'https://zhishi.sdwanyue.com/default.png',
				nickname: '',
				mobile: '',
				is_auth: false,
				access_token: ''
			}
		},
		// 设置搜索历史记录
		setSearchHistory(state, payload) {
			state.$localConfig.search.history = payload
		},
		// 设置搜索配置
		setSearchConfig(state, payload) {
			state.$localConfig.search.tip = payload.tips
			state.$localConfig.search.hot = payload.hotList
		},
		//切换车型
		changeModel(state, payload) {
			state.$drivingConfig.model = payload
			state.$drivingConfig.record_offset = { 1: 0, 4: 0 }
		},
		//切换科目
		changeSubject(state, payload) {
			state.$drivingConfig.subject = payload
		},
		//切换地区
		changePosition(state, payload) {
			state.$drivingConfig.position = payload.name
			state.$drivingConfig.position_code = payload.citycode
			state.$drivingConfig.record_offset = { 1: 0, 4: 0 }
		},
		//切换设置
		changeSetting(state, payload) {
			state.$drivingConfig[payload.key] = payload.value
		},
		//切换自动移除错题
		changeAutoRemove(state, payload) {
			state.$drivingConfig.auto_remove = payload
		},
		//刷新做题记录
		refreshRecordConfig(state, payload) {
			state.$drivingConfig.record_config = payload
		},
		//刷新记录时间
		refreshRecordConfigTime(state, payload) {
			state.$drivingConfig.record_config_time = payload
		},
		//切换做题Offset
		changePanelOffset(state, payload) {
			state.$drivingConfig.record_offset[payload.subject] = payload.index
		},
		//切换专项练习Offset
		changeCategoryOffset(state, payload) {
			state.$drivingConfig.category_offset[payload.id] = payload.index
		},
		setVolunteerInfo(state, payload) {
			state.$volunteerConfig.position = payload.position
			state.$volunteerConfig.type = payload.type
			state.$volunteerConfig.xuanke_first = payload.xuanke_first
			state.$volunteerConfig.xuanke = payload.xuanke
			state.$volunteerConfig.score = payload.score
			state.$volunteerConfig.rank = payload.rank
		},
		setVolunteerWish(state, payload) {
			state.$volunteerConfig.sheet = payload
		},
		setVolunteerBatchId(state, payload) {
			state.$volunteerConfig.batchId = payload
		},
		setVolunteerSheet(state, payload) {
			state.$volunteerConfig.sheet = payload
		},
	},
	actions: {
		//获取用户信息
		getUserInfo(store) {
			uni.$u.http.get('/v1/user/auth/info').then((data) => {
				store.commit('setUserInfo', data);
			}).catch((err) => {
				console.error(err)
			})
		},
		//刷新做题记录
		refreshRecordConfig(store) {
			uni.$u.http.get('/v2/driving/record/config', {
				params: {
					model: store.state.$drivingConfig.model,
					city_id: store.state.$drivingConfig.position_code
				}
			}).then((data) => {
				store.commit('refreshRecordConfig', data.config);
				store.commit('refreshRecordConfigTime', data.time);
			}).catch(err => {
				console.error('store action error', err)
			})
		}
	}
})

export default store