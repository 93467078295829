import { computed } from '@vue/composition-api'
import config from '@/common/config'
import { usePlatform, useSystemInfo, useBrowserInfo, useLaunchInfo } from '@/hooks/useSystemInfo.js'
// import { go } from '@/hooks/usePage.js'
import store from '@/store'
import { getQueryString, deleteQueryString } from '@/util/tool.js'

/**
 * uni.login登陆换取code
 * @returns { Promise<string> } code
 */
export function login() {
	return new Promise((resolve, reject) => {
		uni.login({
			success: (res) => {
				resolve(res.code)
			},
			fail: (err) => {
				reject(err)
			}
		})
	})
}

/**
 * uni.checkSession, 确保小程序在登陆状态
 * @returns { Promise<string | undefined> } code
 */
export function checkLogin() {
	return new Promise((resolve) => {
		uni.checkSession({
			success() {
				resolve(undefined)
			},
			async fail() {
				const code = await login()
				resolve(code)
			},
		});
	})
}

/**
 * 混合登陆，返回code和platform
 * @returns { Promise<{ userCode: string; platform: string; complete: boolean}> }
 */
export async function useMixLogin() {






	//TODO 如果已经登录，则不会进入这里，url上的参数则不会删除
	const token = getQueryString('token');
	const wx_code = getQueryString('code');
	const ali_code = getQueryString('auth_code');
	//清除code
	window.history.replaceState({}, '', deleteQueryString(['code', 'auth_code', 'token']));
	if (token) {
		return Promise.resolve({ userCode: token, platform: 'token', complete: true })
	} else if (wx_code) {
		return Promise.resolve({ userCode: wx_code, platform: 'wx_h5', complete: false })
	} else if (ali_code) {
		return Promise.resolve({ userCode: ali_code, platform: 'ali_h5', complete: false })
	}
	const { isWeChatBrowser } = useBrowserInfo()
	if (isWeChatBrowser) {
		setTimeout(() => {
			const callbackUrl = encodeURIComponent(window.location.href)
			window.location.href =
				`https://open.weixin.qq.com/connect/oauth2/authorize?appid=${config.thirdAppId || 'wx482f4d326c18e077'}&redirect_uri=${callbackUrl}&response_type=code&scope=snsapi_userinfo`;
		}, 500)
	} else {
		store.state.h5LoginShow = true
		// go('/pages/member/login')
		return new Promise((resolve) => {
			uni.$once('h5-login', () => {
				resolve({ userCode: '', platform: '', complete: true })
			})
		})
	}

}

/**
 * 用户信息
 */
export function useUserInfo() {
	return computed(() => store.state.$userInfo)
}

/**
 * 初始化用户，有就登陆，没有就注册
 */
export async function initializeUser() {
	try {
		const { userCode, platform, complete } = await useMixLogin()
		//h5（非微信浏览器）登陆会直接选择用户，不需要再调用login接口
		if (complete) {
			if (platform == 'token') {
				store.commit('setToken', userCode)
				refreshUserInfo()
			}
			return Promise.resolve()
		}
		//小程序登陆
		const { query } = useLaunchInfo()
		const { model, brand, system, version, appName, SDKVersion, deviceId } = useSystemInfo()
		const data = await uni.$u.http.post('/v1/user/auth/login', { model, brand, system, version, appName, SDKVersion, deviceId, platform, userCode, dou_shop_user_id: query.dou_shop_user_id })
		setUserInfo(data)

		return Promise.resolve()
	} catch (error) {
		logout()
		return Promise.reject()
	}
}

/**
 * 刷新用户信息
 */
export function refreshUserInfo() {
	store.dispatch('getUserInfo')
}

/**
 * 设置用户信息
 */
export async function setUserInfo(data) {
	const { appVersion, uniPlatform } = useSystemInfo()
	store.commit('setUserInfo', data)
	uni.setStorage({ key: 'user_id', data: data.user_id })
	uni.setStorage({
		key: 'wmUserInfo',
		data: JSON.stringify({ userId: data.user_id, userTag: uniPlatform, env: 'pro', projectVersion: appVersion })
	})
}

/**
 * 登陆信息
 */
export function useLoginInfo() {
	const isLogin = computed(() => store.state.$userInfo.user_id)

	return {
		isLogin
	}
}

/**
 * 退出登陆
 */
export function logout() {
	store.commit('logout')
}

/**
 * 分享信息
 */
export function useShareConfigInfo() {
	return computed(() => store.state.$localConfig.shareConfig)
}