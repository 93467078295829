// CompositionAPI导入的生命周期需要注册，mixin进行全局注册
import config from '@/common/config.js'
import { isTrue, isEmpty, isNull } from '@/util/is.js'
import { copy, preview } from '@/util/tool.js'
import { useSystemInfo } from '@/hooks/useSystemInfo.js'

export default {
	data() {
		return {
			appName: config.appName,
			canDisplay: !(useSystemInfo().os === 'ios' && useSystemInfo().uniPlatform === 'mp-weixin')
		}
	},
	methods: {
		isTrue,
		isEmpty,
		isNull,
		copy,
		preview,
	},
	onShareAppMessage(){
		return {
			desc: "学知识，学技能",
			title: `学知识就上${config.appName}`
		}
	},
	onShareTimeline(){
		return {
			desc: "学知识，学技能",
			title: `学知识就上${config.appName}`
		}
	}
}