/**
 * 判断一个变量是否为true
 * @param { any } arg
 */
export function isTrue(arg) {
	return arg !== false && arg !== 'false' && arg !== 0 && arg !== '0' && arg !== '2' && arg !== 2 && arg !== ''
		&& arg !== null && arg !== undefined

}

/**
 * 判断一个变量是否为空
 * @param { any } v
 */
export function isEmpty(v) {
	switch (typeof v) {
		case 'undefined':
			return true;
		case 'string':
			if (v.replace(/(^[ \t\n\r]*)|([ \t\n\r]*$)/g, '').length == 0) return true;
			break;
		case 'boolean':
			if (!v) return true;
			break;
		case 'number':
			if (0 === v || isNaN(v)) return true;
			break;
		case 'object':
			if (null === v || v.length === 0) return true;
			for (let i in v) {
				return false;
			}
			return true;
	}
	return false;
}

/**
 * 判断一个变量是否为数字
 * @param { any } value
 */
export function isNumber(value) {
	return /^[\+-]?(\d+\.?\d*|\.\d+|\d\.\d+e\+\d+)$/.test(String(value))
}

/**
 * 判断一个变量是否为undefined或null
 * @param { any } value
 */
export function isNull(value) {
	return value === null || value === undefined;
}