/**
 * 查询授权，如果授权则resolve，没有则去申请授权
 * @param { string } word 需要获取的权限
 * authSetting[code] == undefined(第一次授权) -> 抖音 -> resolve(抖音自动弹出授权)
 * 											-> 快手/微信 -> uni.authorize
 * authSetting[code] == false(之前授权过) -> openSetting
 */
export function authorize(word) {
	const code = getCode(word)
	return new Promise((resolve, reject) => {
		uni.getSetting({
			success(res) {
				const result = res.authSetting[code]
				if (result) {
					resolve()
				} else if (result == undefined) {














				} else {
					uni.openSetting({
						success(result) {
							if (result.authSetting[code]) {
								resolve()
							} else {
								uni.showToast({ icon: 'none', title: '请添加相应权限' })
								reject()
							}
						},
						fail() {
							uni.showToast({ icon: 'none', title: '打开授权页面失败' })
							reject()
						}
					})
				}
			},
			fail() {
				uni.showToast({ icon: 'none', title: '获取权限失败' })
				reject()
			}
		})
	})
}

/**
 * 获取授权code（适配各家小程序）
 * @param { string } code 需要获取的权限
 */
export function getCode(code) {





	return code
}